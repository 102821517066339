import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {PathNames, PathParams} from './app-routing-pathnames';
import {AuthService} from './common/services/auth.service';


@Injectable()
export class ApplicationFlowGuard implements CanActivate {

  private currentPage = -1;

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const carSaleId = route.paramMap.get(PathParams.carSaleId);

    const targetPageName = Object.keys(PathNames).find(key => PathNames[key].url === route.url[0].path);
    const targetPage = PathNames[targetPageName].count;
    const localPage = Number(localStorage.getItem('currentPage'));

    let reloaded = false;

    // Only activate if navigating to dynamic form page
    if (targetPage != null) {
      // Activates if page has been reloaded
      if (this.currentPage === -1) {
        this.currentPage = localPage ? localPage : 0;
        reloaded = true;
      }

      if (targetPage < this.currentPage || (targetPage === this.currentPage || targetPage === this.currentPage + 1) && !reloaded) {
        // update current page on successful navigation
        localStorage.setItem('currentPage', targetPage.toString());
        this.currentPage = targetPage;
      } else {
        // navigate to last currentPage
        const previousPageName = Object.keys(PathNames).find(key => PathNames[key].count === this.currentPage);
        this.router.navigate([PathNames[previousPageName].url, carSaleId]);
      }

      // prevent back-navigation if on Successful Proposal page
      const applicationSate = this.authService.currentApplicationState();
      const lastPage = applicationSate ? applicationSate.lastPage : null;
      if (lastPage === 'successful_proposal') {
        this.router.navigate([PathNames.proposalResultPage.url, carSaleId]);
      }
    }

    // return route activation flag
    return true;
  }
}
