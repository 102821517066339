import { Component, OnInit, Input, Injector, DoCheck } from '@angular/core';
import { PathNames } from '../../app-routing-pathnames';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AppInjectorService } from '../../shared/services/app-injector.service';

@Component({
  selector: 'app-navigation-bar-mobile',
  templateUrl: './navigation-bar-mobile.component.html',
  styleUrls: ['./navigation-bar-mobile.component.scss']
})
export class NavigationMobileComponent implements OnInit, DoCheck {
  progressBarStatus = 0;
  pages = [];
  currentPage;
  visible = false;

  protected injector: Injector;
  protected router: Router;
  protected authService: AuthService;

  constructor() {
    this.injector = AppInjectorService.getInjector();
    this.router = this.injector.get(Router);
    this.authService = this.injector.get(AuthService);
  }

  ngOnInit() {
    this.pages = Object.entries(PathNames).map(page => page[1])
      .filter(page =>
        page !== null && page !== undefined &&
        page.count !== null && page.count !== undefined ? typeof(page.count) === 'number' && page.count > 0 : false);
  }

  ngDoCheck() {
    this.currentPage = this.pages.find(page => window.location.pathname.includes(page.url));
    if (this.currentPage !== null && this.currentPage !== undefined) {
      this.progressBarStatus = Math.round((100 / this.pages.length) * this.currentPage.count);
    }
  }

  getCount() {
    if (this.currentPage !== null && this.currentPage !== undefined) {
      return this.currentPage.count;
    }
  }

  getValue() {
    if (this.currentPage !== null && this.currentPage !== undefined) {
      return Math.round((100 / this.pages.length) * this.currentPage.count);
    }
  }

  showBackButton() {
    return this.currentPage !== null && this.currentPage !== undefined && this.currentPage.count > 1;
  }

  showProgressBar() {
    return this.currentPage !== null && this.currentPage !== undefined;
  }

  back() {
    this.router.navigate([this.pages.find(page => page.count === this.currentPage.count - 1).url, this.authService.currentCarSaleId()]);
  }
}
