import { Tariff } from '@coc-kfz-digital/oma-rest-api-client';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Routing } from '../no-result-tile/no-result-tile.component';

@Component({
  selector: 'app-tile-list',
  templateUrl: './tile-list.component.html',
  styleUrls: ['./tile-list.component.scss']
})
export class TileListComponent {

  @Input() tariffs: Array<Tariff> = [];
  @Input() selectTariffLabel: string;
  @Input() lastPageRouting: Routing;
  @Input() submitted = false;

  @Output()
  selected: EventEmitter<Tariff> = new EventEmitter<Tariff>();

}
