import { Component, OnInit } from '@angular/core';
import { FormField } from '@coc-kfz-digital/oma-rest-api-client';
import { FormGroup } from '@angular/forms';
import { AbstractFormFieldComponent } from '../abstract-form-field/abstract-form-field.component';

@Component({
  selector: 'app-form-radio',
  templateUrl: './form-radio.component.html',
  styleUrls: ['./form-radio.component.scss']
})
export class FormRadioComponent extends AbstractFormFieldComponent {
}
