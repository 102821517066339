import { FormField, ValidationRule } from '@coc-kfz-digital/oma-rest-api-client';
import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { INSURANCE_PROVIDER_CREDITOR_MAPPING } from 'src/app/common/insurer-mappings';
import { FormStatus, LookupFormValues } from '../../containers/dynamic-form/dynamic-form.component';

@Injectable()
export abstract class AbstractFormFieldComponent {

  fieldConfig?: FormField;
  group: FormGroup;
  lookupFormValues: LookupFormValues;
  elResolveTarget: any;
  status: FormStatus;

  constructor() {
  }

  /**
   * Returns the form group control this component is bound to
   */
  control(): AbstractControl {
    return this.group.controls[this.fieldConfig.name];
  }

  /**
   * Returns the form values that are available for this control.
   *
   * For an ordinary control the values from the ui field specification will be returned.
   * If the control is a lookup value receiver the values provided by the lookup service will be returned.
   */
  getFormValues() {
    const lookupValueReceiver = this.fieldConfig.type ? this.fieldConfig.type.lookupValueReceiver : false;

    if (lookupValueReceiver === true) {

      // use lookup values
      const lookupValues = this.lookupFormValues[this.fieldConfig.name];
      return lookupValues ? lookupValues : [];
    } else {

      // use ordinary form field values
      return this.fieldConfig.values;
    }
  }

  /**
   * Returns an ng class object that holds dynamic css classes based on the underlying form state of the dynamic form component.
   *
   * Should be used by child components to set the ngClass attribute of the form element.
   * Doing so will for example hide the form component if it form state is currently disabled.
   *
   * Example:
   * <input type="text" [formControlName]="fieldConfig.name" [ngClass]="getNgClassEnhancements()" />
   */
  getNgClassEnhancements() {
    return { 'disabled-dynamic-field': this.control().disabled };
  }

  protected getSplittedTranslationComponents(labelKeyAndParam: string): string[] {
    if (labelKeyAndParam) {
      return labelKeyAndParam.split('##');
    } else {
      return [labelKeyAndParam];
    }
  }

  getTranslationKey(labelKeyAndParam: string): string {
    return this.getSplittedTranslationComponents(labelKeyAndParam)[0];
  }

  public getTranslationParams(labelKeyAndParam: string): object {
    const parameter = this.getSplittedTranslationComponents(labelKeyAndParam)[1];
    // special case: calculate direct debit mandate
    if (parameter === 'calculate-direct-debit-mandate') {
      return this.calculateDirectDebitMandate();
    }
    // return empty translation parameter object
    return {};
  }

  /**
   * Return translation parameter object for direct debit mandate
   */
  private calculateDirectDebitMandate(): object {
    const providerId: string = this.group.controls.selectedTarifProviderId ? this.group.controls.selectedTarifProviderId.value : ' ';
    const providerName: string = this.group.controls.selectedTarifProviderName ?
      this.group.controls.selectedTarifProviderName.value : '[Firmierung Versicherung]';
    const creditorId: string = INSURANCE_PROVIDER_CREDITOR_MAPPING[providerId] ?
      INSURANCE_PROVIDER_CREDITOR_MAPPING[providerId] : '[Gläubiger-ID]';

    return {
      providerName,
      creditorId,
    };
  }

  /**
   * Returns an array of unsatisfied validation rule of the form field or null
   */
  public getValidationErrors(): ValidationRule[] | null {
    const errors = this.control().errors;

    if (errors === null) {
      return null;
    }

    const validatorNames = Object.keys(errors);

    return this.fieldConfig.validationRules.filter(validationRule => {
      return validatorNames.includes(validationRule.name);
    });
  }

  /**
   * Returns the first unsatisfied validation rule of the form field or null
   */
  public getFirstValidationError(): ValidationRule | null {
    const errors = this.getValidationErrors();

    if (errors === null) {
      return null;
    }

    return (errors.length >= 1) ? errors[0] : null;
  }

  /**
   * Returns the message of the first unsatisfied validation rule
   */
  public getValidationMessage(): string | null {
    const error = this.getFirstValidationError();
    return (error && error.message) ? error.message : null;
  }

  /**
   * Returns whether to show validation message or not
   */
  public showValidationMessage(): boolean {
    return this.control().invalid && (this.control().dirty || this.control().touched);
  }
}
