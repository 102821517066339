import {FormValue} from '@coc-kfz-digital/oma-rest-api-client';
import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AppInjectorService} from 'src/app/shared/services/app-injector.service';
import {AbstractFormFieldComponent} from '../abstract-form-field/abstract-form-field.component';

@Component({
  selector: 'app-form-autosuggest-dropdown',
  templateUrl: './form-autosuggest-dropdown.component.html',
  styleUrls: ['./form-autosuggest-dropdown.component.scss']
})

export class FormAutosuggestDropdownComponent extends AbstractFormFieldComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.control().value < 2) {
      this.control().setValue(null);
    }
  }

  _filter(searchTerm: string, item: FormValue) {
    const term = searchTerm.toLowerCase();
    const label = AppInjectorService.getInjector().get(TranslateService).instant(item.label).toLowerCase();

    /**
     * Adaptive search strategy to cover different search use cases.
     * a) For very short search terms (<= 3) we filter with exact-matching from the beginning of the label.
     * b) For longer search terms (>= 4) we filter with additional weaker include-matching to allow matches anywhere inside the label.
     *
     * Magic number 3 seems to avoid many ambiguous matches in German language.
     * For example the phrase 'sch' is only significant at the beginning of a word but not in the middle).
     */
    if (term.length <= 3) {
      return label.startsWith(term);
    } else {
      return label.startsWith(term) || label.includes(term);
    }
  }

  /**
   * Returns whether the dropdown should have a clear button
   */
  isClearable(): boolean {
    // show clear button only if a value is selected
    return (this.control().value) ? true : false;
  }
}
