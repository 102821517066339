export interface PathName {
  [key: string]: Path;
}

export interface Path {
  url: string;
  name: string;
  count: number;
}

export const PathNames: PathName = {
    empty: { url: '', name: '', count: null },
    loginPage: { url: 'login', name: 'Anmeldung', count: null },
    quickCalculationPage: { url: 'schnellberechnung', name: 'Schnellberechnung', count: 1 },
    comparativeResultsPage: { url: 'tarifauswahl', name: 'Tarifauswahl', count: 2 },
    detailedCalculationPage: { url: 'detailberechnung', name: 'Detailberechnung', count: 3 },
    detailedResultsPage: { url: 'persoenliches-angebot', name: 'Persönliches Angebot', count: 4 },
    previousInsurerPage: { url: 'antrag', name: 'Antrag', count: 5 },
    paymentDetailsPage: { url: 'konto', name: 'Konto', count: 6 },
    summaryPage: { url: 'zusammenfassung', name: 'Zusammenfassung', count: 7 },
    proposalResultPage: { url: 'abschluss', name: 'Abschluss' , count: null },
    errorPage: { url: 'fehler', name: 'Fehlerseite' , count: null },
    privacyNotice: { url: 'datenschutzerklaerung' , name: 'Datenschutzerklärung', count: null },
    termsOfService: { url: 'nutzungsbedingungen' , name: 'Nutzungsbedingungen', count: null },
    imprintPage: { url: 'impressum' , name: 'Impressum', count: null },
    pageNotFoundPage: { url: '**' , name: '', count: null }
};

export const PathParams = {
    carSaleId: 'carsaleId'
};
