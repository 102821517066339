import {Component} from '@angular/core';
import {BrandingFacadeService} from '../services/branding-facade.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  public sellerName$ = this.brandingFacadeService.name$;
  public telephoneNumber$ = this.brandingFacadeService.phone$;
  public email$ = this.brandingFacadeService.email$;

  constructor(private brandingFacadeService: BrandingFacadeService) {
  }
}
