import { Location } from '@angular/common';
import { Component, OnInit, Input, Injector } from '@angular/core';
import { PathNames } from 'src/app/app-routing-pathnames';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AppInjectorService } from '../../shared/services/app-injector.service';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationComponent implements OnInit {

  @Input() pageName: string;
  activeNumber = 0;
  pages = [];
  visible = false;

  protected injector: Injector;
  protected router: Router;
  protected authService: AuthService;

  constructor(private location: Location) {
    this.injector = AppInjectorService.getInjector();
    this.router = this.injector.get(Router);
    this.authService = this.injector.get(AuthService);
  }

  ngOnInit() {
    this.pages = Object.entries(PathNames).map(key => (key[1]));
    this.activeNumber = this.pages.find(page => page.url === this.pageName).count;
  }

  routeTo(page) {
    if (page.count < this.activeNumber) {
      this.router.navigate([page.url, this.authService.currentCarSaleId()]);
    }
  }

  checkItem(currentnumber: number) {
    if (currentnumber === this.activeNumber) {
      return { 'nagivation-item-active': true };
    }
    if (currentnumber > this.activeNumber) {
      return { 'nagivation-item-disable': true };
    } else {
      return { 'nagivation-item-inactive': true };
    }
  }
}
