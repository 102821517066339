import {Component} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BrandingFacadeService} from '../services/branding-facade.service';

@Component({
  selector: 'app-ma-fab-button',
  templateUrl: './ma-fab-button.component.html',
  styleUrls: ['./ma-fab-button.component.scss']
})
export class MaFabButtonComponent {
  constructor(public dialog: MatDialog) {
  }

  openDialog(): void {
    // TODO: should not be a problem, could be fixed with next angular cli update/version
    // tslint:disable-next-line:no-use-before-declare
    const dialogRef = this.dialog.open(MaFabDialogComponent, { width: '550px', autoFocus: false,
      maxHeight: '90vh', panelClass: 'custom-modalbox'});

    dialogRef.afterClosed().subscribe(result => {});
  }
}

@Component({
  selector: 'app-ma-fab-button-dialog',
  templateUrl: './ma-fab-overview.component.html',
})
export class MaFabDialogComponent {

  public telephoneNumber$ = this.brandingService.phone$;

  constructor(
    public dialogRef: MatDialogRef<MaFabDialogComponent>,
    public brandingService: BrandingFacadeService) {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}



