import { Subscription } from 'rxjs';

export class Utils {
}

/**
 * Null safe unsubscribe of the given subscription
 * @param subscription the subscription
 */
export function safeUnsubscribe(subscription: Subscription) {
    if (subscription) {
        subscription.unsubscribe();
    }
}
