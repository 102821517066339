import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {BrandingFacadeService} from '../services/branding-facade.service';
import {map, tap} from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy {

  public logoUrl$ = this.brandingFacadeService.logoUrl$;
  public logoutEnabled$ = this.brandingFacadeService.carSaleId$.pipe(
    map(carSaleId => this.authService.currentCarSaleId() !== null && carSaleId === this.authService.currentCarSaleId()),
  );

  private logoutSubscription: Subscription;
  private carSaleId: string;

  constructor(private authService: AuthService, private brandingFacadeService: BrandingFacadeService) {
  }

  ngOnDestroy(): void {
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
  }

  private onLogout(): void {
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
    this.logoutSubscription = this.authService.logout().subscribe();
  }
}
