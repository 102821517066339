import { FormField, FormFieldType } from '@coc-kfz-digital/oma-rest-api-client';
import { ComponentFactoryResolver, ComponentRef, Directive, Input, OnInit, Type, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormStatus, LookupFormValues } from '../containers/dynamic-form/dynamic-form.component';
import { AbstractFormFieldComponent } from './abstract-form-field/abstract-form-field.component';
import { FormAutosuggestDropdownComponent } from './form-autosuggest-dropdown/form-autosuggest-dropdown.component';
import { FormButtonComponent } from './form-button/form-button.component';
import { FormCheckboxComponent } from './form-checkbox/form-checkbox.component';
import { FormCheckmarkboxComponent } from './form-checkmarkbox/form-checkmarkbox.component';
import { FormDatepickerComponent } from './form-datepicker/form-datepicker.component';
import { FormDropdownComponent } from './form-dropdown/form-dropdown.component';
import { FormHiddenInputComponent } from './form-hidden-input/form-hidden-input.component';
import { FormInputComponent } from './form-input/form-input.component';
import { FormLabelComponent } from './form-label/form-label.component';
import { FormModalComponent } from './form-modal/form-modal.component';
import { FormQuickToggleDatepickerComponent } from './form-quick-toggle-datepicker/form-quick-toggle-datepicker.component';
import { FormRadioComponent } from './form-radio/form-radio.component';
import { FormSliderComponent } from './form-slider/form-slider.component';

/**
 * Interface for mapping ui types to dynamic components
 * @see FormFieldType.UiTypeEnum
 * @see AbstractFormFieldComponent
 */
export type UITypeComponentMapping = {
  [key in FormFieldType.UiTypeEnum]: Type<AbstractFormFieldComponent>;
};

/**
 * Mapping of ui types to dynamic components
 * @see FormFieldType.UiTypeEnum
 */
export const components: UITypeComponentMapping = {
  button: FormButtonComponent,
  inputtext: FormInputComponent,
  datepicker: FormDatepickerComponent,
  radio: FormRadioComponent,
  slider: FormSliderComponent,
  dropdown: FormDropdownComponent,
  label: FormLabelComponent,
  modal: FormModalComponent,
  quicktoggledatepicker: FormQuickToggleDatepickerComponent,
  checkbox: FormCheckboxComponent,
  hidden: FormHiddenInputComponent,
  autosuggestdropdown: FormAutosuggestDropdownComponent,
  checkmarkbox: FormCheckmarkboxComponent
};

@Directive({
  selector: '[appDynamicField]'
})
export class DynamicFieldDirective implements OnInit {
  @Input() fieldConfig: FormField;
  @Input() group: FormGroup;
  @Input() lookupFormValues: LookupFormValues;
  @Input() elResolveTarget: any;
  @Input() status: FormStatus;

  component: ComponentRef<AbstractFormFieldComponent>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) { }

  ngOnInit() {
    const component = components[this.fieldConfig.type.uiType];

    if (!component) {
      this.logError();
    } else {
      const factory = this.resolver.resolveComponentFactory<AbstractFormFieldComponent>(component);
      this.component = this.container.createComponent(factory);
      this.component.instance.fieldConfig = this.fieldConfig;
      this.component.instance.group = this.group;
      this.component.instance.lookupFormValues = this.lookupFormValues;
      this.component.instance.elResolveTarget = this.elResolveTarget;
      this.component.instance.status = this.status;
    }
  }

  private logError() {
    const error = 'Error creating component instance. Given uiType [' +
      this.fieldConfig.type.uiType + '] was not found for field [' +
      this.fieldConfig.name + ']. Please add the uiType to the component mapping declared in DynamicFieldDirective::components';

    console.error(error);
  }
}
