import { Tariff } from '@coc-kfz-digital/oma-rest-api-client';
import { CalculationResponse } from '@coc-kfz-digital/oma-rest-api-client/model/calculationResponse';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Routing } from 'src/app/common/results-pages/no-result-tile/no-result-tile.component';
import { safeUnsubscribe } from 'src/app/shared/utils';
import { AbstractPageComponent } from '../abstract-page/abstract-page.component';

@Component({
  template: '',
  styleUrls: ['./abstract-result-page.component.scss']
})
export class AbstractResultPageComponent extends AbstractPageComponent implements OnInit, OnDestroy {

  protected calculationSubscription: Subscription;

  public calculationResponse: CalculationResponse = {};

  tariffs: Array<Tariff> = [];

  protected filterBySelectedTariff = false;

  protected lastPageRouting: Routing;
  isLoading = true;
  submitted = false;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscribeToCalculation();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    safeUnsubscribe(this.calculationSubscription);
  }

  protected subscribeToCalculation() {
    const calculationObservable = this.carInsuranceCalculationService.executeCalculation(this.filterBySelectedTariff);
    this.calculationSubscription = calculationObservable.subscribe(calcResponse => {
      this.refreshCalculationData(calcResponse);
      this.isLoading = false;
    });
  }

  protected refreshCalculationData(calcResponse: CalculationResponse) {
    this.calculationResponse = calcResponse;
    this.tariffs = calcResponse.items;
  }

}
