import { Config } from './config';
import { environmentBase } from './environment.base';

export const environment: Config = {
  ...environmentBase,
  API_BASE_PATH: 'https://backend.dev2.tvd.cc-n.dev',
  dynamic_form_debug: false,
  application_state_debug: false,
  google: {
    tag_manager: 'GTM-TRL2MN9',
    analytics: 'UA-175878685-1'
  }
};
