import { CarSale } from '@coc-kfz-digital/oma-rest-api-client';
import { Component } from '@angular/core';
import * as moment from 'moment';
import { AbstractFormFieldComponent } from '../abstract-form-field/abstract-form-field.component';
import {BrandingFacadeService} from '../../../common/services/branding-facade.service';

@Component({
  selector: 'app-form-label',
  templateUrl: './form-label.component.html',
  styleUrls: ['./form-label.component.scss']
})
export class FormLabelComponent extends AbstractFormFieldComponent {

  carSale: CarSale;
  private telephoneNumber: string;

  constructor(public brandingService: BrandingFacadeService) {
    super();
    this.brandingService.phone$.subscribe(
      telephoneNumber => this.telephoneNumber = telephoneNumber ?? ''
    )
  }

  getTranslationParams(labelKeyAndParam: string): object {
    super.getTranslationParams(labelKeyAndParam);
    return {
      telephone: this.telephoneNumber,
    };
  }
}

