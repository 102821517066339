import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { Ng5SliderModule } from 'ng5-slider';
import { OmaCommonModule } from '../common/common.module';
import { SharedModule } from '../shared/shared.module';
import { DynamicFieldDirective } from './components/dynamic-field.directive';
import { FormAutosuggestDropdownComponent } from './components/form-autosuggest-dropdown/form-autosuggest-dropdown.component';
import { FormButtonComponent } from './components/form-button/form-button.component';
import { FormCheckboxComponent } from './components/form-checkbox/form-checkbox.component';
import { FormCheckmarkboxComponent } from './components/form-checkmarkbox/form-checkmarkbox.component';
import { FormDatepickerComponent } from './components/form-datepicker/form-datepicker.component';
import { FormDropdownComponent } from './components/form-dropdown/form-dropdown.component';
import { FormHiddenInputComponent } from './components/form-hidden-input/form-hidden-input.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { FormLabelComponent } from './components/form-label/form-label.component';
import { FormModalComponent} from './components/form-modal/form-modal.component';
import { FormQuickToggleDatepickerComponent } from './components/form-quick-toggle-datepicker/form-quick-toggle-datepicker.component';
import { FormRadioComponent } from './components/form-radio/form-radio.component';
import { FormSliderComponent } from './components/form-slider/form-slider.component';
import { FormTooltipComponent } from './components/form-tooltip/form-tooltip.component';
import { DynamicFormComponent } from './containers/dynamic-form/dynamic-form.component';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';


@NgModule({
  declarations: [
    DynamicFieldDirective,
    DynamicFormComponent,
    FormInputComponent,
    FormButtonComponent,
    FormDatepickerComponent,
    FormRadioComponent,
    FormSliderComponent,
    FormLabelComponent,
    FormModalComponent,
    FormQuickToggleDatepickerComponent,
    FormDropdownComponent,
    FormCheckboxComponent,
    FormTooltipComponent,
    FormHiddenInputComponent,
    FormAutosuggestDropdownComponent,
    FormCheckmarkboxComponent,
  ],
  imports: [
    OmaCommonModule,
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    Ng5SliderModule,
    TranslateModule,
    NgSelectModule,
    MatExpansionModule,
    MatIconModule,
    MatDialogModule
  ],
  exports: [DynamicFormComponent],
  entryComponents: [
    // all form components that need to be instanciated dynamically need to be defined here
    FormInputComponent,
    FormButtonComponent,
    FormDatepickerComponent,
    FormRadioComponent,
    FormSliderComponent,
    FormDropdownComponent,
    FormLabelComponent,
    FormModalComponent,
    FormQuickToggleDatepickerComponent,
    FormCheckboxComponent,
    FormTooltipComponent,
    FormHiddenInputComponent,
    FormAutosuggestDropdownComponent,
    FormCheckmarkboxComponent
  ],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] }
  ],
})
export class DynamicFormModule { }
