import { Component } from '@angular/core';
import { AbstractFormFieldComponent } from '../abstract-form-field/abstract-form-field.component';

@Component({
  selector: 'app-form-hidden-input',
  templateUrl: './form-hidden-input.component.html',
  styleUrls: ['./form-hidden-input.component.scss']
})
export class FormHiddenInputComponent extends AbstractFormFieldComponent {
}
