import { AbstractRequest, FormUiSpecificationName, UIMetadataService } from '@coc-kfz-digital/oma-rest-api-client';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { toRestoreStateObservable } from 'src/app/common/request-parameter-utils';
import { DynamicFormContent, FormStatus } from 'src/app/dynamic-form/containers/dynamic-form/dynamic-form.component';
import { safeUnsubscribe } from 'src/app/shared/utils';
import { AbstractPageComponent } from '../abstract-page/abstract-page.component';

@Injectable()
export abstract class AbstractDynamicFormPageComponent extends AbstractPageComponent implements OnInit, OnDestroy {

  status: FormStatus = { submitted: false };

  protected uiMetadataService: UIMetadataService;

  dynamicFormObservable: Observable<DynamicFormContent>;
  dynamicFormSubscription: Subscription;
  dynamicFormData: DynamicFormContent;

  constructor() {
    super();
    this.uiMetadataService = this.injector.get(UIMetadataService);

    // initialize dynamic form data
    this.dynamicFormObservable = this.getDynamicFormContent(this.getPageName());
  }

  ngOnInit() {
    super.ngOnInit();

    // load dynamic form data
    this.dynamicFormSubscription = this.dynamicFormObservable.subscribe(value => this.dynamicFormData = value);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    // cleanup subscriptions
    safeUnsubscribe(this.dynamicFormSubscription);
  }

  /**
   * Returns the current page name. Used to automatically retrieve form ui specification for different pages.
   */
  protected abstract getPageName(): FormUiSpecificationName;

  /**
   * Returns an observable to load all needed dynamic form parameters for the given page simultaneously
   *
   * @param pageName the page to evaluate
   */
  protected getDynamicFormContent(pageName: FormUiSpecificationName): Observable<DynamicFormContent> {
    const parameterRequestObservable = this.getRestoreStateObservableForPage(pageName);

    /** build and return a combined observable that emits one value when all source observables complete
     * @see https://www.learnrxjs.io/operators/combination/forkjoin.html
     * @see https://www.learnrxjs.io/operators/transformation/switchmap.html
     * @see https://www.learnrxjs.io/operators/creation/of.html
     */
    return forkJoin(
      {
        uiModel: this.uiMetadataService.getUiSpecForms(pageName)
          .pipe(switchMap(response => of((response.uiModel)))),
        elResolveTarget: this.carSaleCacheService.getCarSale().pipe(switchMap(carsale => of(({ carsale })))),
        restoreState: toRestoreStateObservable(parameterRequestObservable)
      });
  }

  /**
   * Returns the matching observable to load request parameters for the given page.
   *
   * @param pageName the page to evaluate
   * @returns CarInsuranceCalculationService::getCalculation - for a calculation page
   *          CarInsuranceProposalService::getProposal - for a proposal page
   */
  private getRestoreStateObservableForPage(pageName: FormUiSpecificationName): Observable<AbstractRequest> {

    switch (pageName) {
      // calculation pages use calculation service to retrieve parameters
      case FormUiSpecificationName._1QuickCalculationStepJson:
      case FormUiSpecificationName._2DetailedCalculationStepJson:
        return this.carInsuranceCalculationService.getCalculation();

      // proposal pages use proposal service to retrieve parameters
      case FormUiSpecificationName._3CarDetailsInsurerProposalStepJson:
      case FormUiSpecificationName._4PaymentDetailsProposalStepJson:
        return this.carInsuranceProposalService.getProposal();
      default:
        throw new Error('Unknown page name [' + pageName + ']. '
          + 'Did you forget add a mapping in AbstractDynamicFormPageComponent::getRestoreStateObservableForPage'
          + 'to retrieve restore state for the given page?. ');
    }
  }
}
