import { Component, Input, OnInit } from '@angular/core';
import { INSURANCE_PROVIDER_IMAGE_MAPPING } from '../../insurer-mappings';


@Component({
  selector: 'app-insurer-logo',
  templateUrl: './insurer-logo.component.html',
  styleUrls: ['./insurer-logo.component.scss']
})
export class InsurerLogoComponent implements OnInit {

  private static IMAGE_BASE_PATH = '../../assets/comparative-results-page/logos/';

  @Input() imageId: string;

  imagePath: string;

  ngOnInit() {
    this.imagePath = InsurerLogoComponent.IMAGE_BASE_PATH + this.getFileNameById(this.imageId);
  }

  getFileNameById(id: string) {
    const fileName = INSURANCE_PROVIDER_IMAGE_MAPPING[id];
    return (fileName) ? fileName : 'missing-logo.png';
  }
}
