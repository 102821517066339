import {Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../environments/environment';
import {BrandingFacadeService} from './common/services/branding-facade.service';

// tslint:disable-next-line:ban-types
declare let gtag: Function;
declare var Cookiebot: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  constructor(private translationService: TranslateService, public router: Router, public brandingService: BrandingFacadeService) {
    translationService.setDefaultLang('de');
    // keep brandingService in the constructor to make sure it gets initialized
  }

  loadScript() {
    const node1 = document.createElement('script');
    node1.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.google.analytics;
    node1.async = true;

    const node2 = document.createElement('script');
    node2.text = 'window.dataLayer = window.dataLayer || [];' +
      'function gtag(){dataLayer.push(arguments);}' +
      'gtag(\'js\', new Date());';

    const node3 = document.createElement('script');
    node3.text = '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':' +
        'new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],' +
        'j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=' +
      '\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);' +
    '})(window,document,\'script\',\'dataLayer\',\'' + environment.google.tag_manager + '\')';

    const head = document.getElementsByTagName('head')[0]
    head.appendChild(node1);
    head.appendChild(node2);
    head.appendChild(node3);
  }

  ngOnInit(): void {
    if(environment.production) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('config', environment.google.analytics,
            {
              page_path: event.urlAfterRedirects,
              anonymize_ip: true
            }
          );
        }
      })

      window.addEventListener('CookiebotOnAccept', e => {
        if (Cookiebot.consent.statistics) {
          this.loadScript();
        }
      }, false);

    }
  }
}
