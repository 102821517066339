import { FormField, FormSection } from '@coc-kfz-digital/oma-rest-api-client';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AbstractFormFieldComponent } from '../abstract-form-field/abstract-form-field.component';



/**
 * This component adds Tooltips to the encapsulated HTML
 * everything encapsulated can be pressed to expand the tooltip
 * An Icon to notify the user that there is a tooltip needs to be added at the desired location
 */

@Component({
  selector: 'app-form-tooltip',
  templateUrl: './form-tooltip.component.html',
  styleUrls: ['./form-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class FormTooltipComponent extends AbstractFormFieldComponent {

  @Input()
  context: any;

}
