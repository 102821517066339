import { FormValue } from '@coc-kfz-digital/oma-rest-api-client';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomStepDefinition, Options } from 'ng5-slider';
import { AbstractFormFieldComponent } from '../abstract-form-field/abstract-form-field.component';

@Component({
  selector: 'app-form-slider',
  templateUrl: './form-slider.component.html',
  styleUrls: ['./form-slider.component.scss']
})
export class FormSliderComponent extends AbstractFormFieldComponent {

  constructor(private translateService: TranslateService) { super(); }

  createSliderOptions(): Options {
    const options = {
      showTicksValues: true,
      showSelectionBar: true,
      stepsArray: this.getFormValues().map(formValue => this.convertToSliderStep(formValue)),
    };

    return options;
  }

  private convertToSliderStep(formValue: FormValue): CustomStepDefinition {
    return {
      value: parseInt(formValue.value, 10),
      legend: this.translateService.instant(formValue.label),
    };
  }
}
