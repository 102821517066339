import { TariffBenefits } from '@coc-kfz-digital/oma-rest-api-client';
import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-tariff-benefit-list',
  templateUrl: './tariff-benefit-list.component.html',
  styleUrls: ['./tariff-benefit-list.component.scss']
})
export class TariffBenefitListComponent {
  constructor() { }


  @Input() benefits: TariffBenefits = {};

  @Input() displayedBenefits: string[];
}
