import { Component, Input } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import {BrandingFacadeService} from '../../services/branding-facade.service';

export interface Routing {
  commands: any[];
  extras?: NavigationExtras;
}

@Component({
  selector: 'app-no-result-tile',
  templateUrl: './no-result-tile.component.html',
  styleUrls: ['./no-result-tile.component.scss']
})
export class NoResultTileComponent {

  public telephoneNumber$ = this.brandingService.phone$;

  @Input() lastPageRouting: Routing;

  constructor(public router: Router, public brandingService: BrandingFacadeService) {
  }

  public onSubmit() {
    this.redirectToLastPageIfPossible();
  }

  private redirectToLastPageIfPossible() {
    if (this.lastPageRouting && this.lastPageRouting.commands) {
      this.router.navigate(this.lastPageRouting.commands, this.lastPageRouting.extras);
    }
  }

}
