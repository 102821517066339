import { Tariff } from '@coc-kfz-digital/oma-rest-api-client';
import { CalculationRequest } from '@coc-kfz-digital/oma-rest-api-client/model/calculationRequest';
import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PathNames } from 'src/app/app-routing-pathnames';
import { createRequest } from 'src/app/common/request-parameter-utils';
import { FormValues } from 'src/app/dynamic-form/containers/dynamic-form/dynamic-form.component';
import { safeUnsubscribe } from 'src/app/shared/utils';
import { AbstractResultPageComponent } from '../abstract-result-page/abstract-result-page.component';


@Component({
  selector: 'app-comparative-results-page',
  templateUrl: './comparative-results-page.component.html',
  styleUrls: ['./comparative-results-page.component.scss']
})
export class ComparativeResultsPageComponent extends AbstractResultPageComponent implements OnDestroy {

  private calcRequestSubscription: Subscription;

  constructor() {
    super();
    this.filterBySelectedTariff = false;
    this.lastPageRouting = { commands: [PathNames.quickCalculationPage, this.currentCarSaleId()] };
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    safeUnsubscribe(this.calcRequestSubscription);
  }

  onSelect(tariff: Tariff) {
    this.submitted = true;

    // create fake form values to store some informational values into the calculation parameters
    // used by OA-162 (dynamic creditor id)
    const formValues: FormValues = {
      selectedTarifProviderId: tariff.provider.id,
      selectedTarifProviderName: tariff.provider.name
    };
    const calculationRequest: CalculationRequest = createRequest(formValues);
    calculationRequest.selectedTariffId = tariff.id;

    safeUnsubscribe(this.calcRequestSubscription);

    const calcRequestObservable = this.carInsuranceCalculationService.patchCalculation(calculationRequest);

    this.calcRequestSubscription = calcRequestObservable.subscribe({
      complete: () => this.router.navigate([PathNames.detailedCalculationPage.url, this.currentCarSaleId()]),
      error: () => this.submitted = false,
    });
  }
}
