import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormField } from '@coc-kfz-digital/oma-rest-api-client';
import { AbstractFormFieldComponent } from '../abstract-form-field/abstract-form-field.component';

@Component({
  selector: 'app-form-datepicker',
  templateUrl: './form-datepicker.component.html',
  styleUrls: ['./form-datepicker.component.scss']
})
export class FormDatepickerComponent extends AbstractFormFieldComponent {
}
