import { FormUiSpecificationName } from '@coc-kfz-digital/oma-rest-api-client';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PathNames } from 'src/app/app-routing-pathnames';
import { createRequest } from 'src/app/common/request-parameter-utils';
import { FormValues } from 'src/app/dynamic-form/containers/dynamic-form/dynamic-form.component';
import { AbstractDynamicFormPageComponent } from '../abstract-dynamic-form-page/abstract-dynamic-form-page.component';

@Component({
  selector: 'app-previous-insurer-page',
  templateUrl: './previous-insurer-page.component.html',
  styleUrls: ['./previous-insurer-page.component.scss']
})

export class PreviousInsurerPageComponent extends AbstractDynamicFormPageComponent implements OnInit, OnDestroy {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected getPageName(): FormUiSpecificationName {
    return FormUiSpecificationName._3CarDetailsInsurerProposalStepJson;
  }

  /**
   * Event handler method that is invoked by submitting the page form
   * @param formValues the state of the form during submission
   */
  onSubmitted(formValues: FormValues) {
    this.status.submitted = true;

    // prepare request to store form state
    const request = createRequest(formValues);

    // create request observable
    const requestObservable = this.carInsuranceProposalService.patchProposal(request);

    // save request
    requestObservable.subscribe({
      complete: () => this.router.navigate([PathNames.paymentDetailsPage.url, this.currentCarSaleId()]),
      error: () => this.status.submitted = false,
    });
  }
}
