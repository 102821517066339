import { CarSale, CarSaleService } from '@coc-kfz-digital/oma-rest-api-client';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

const CACHE_SIZE = 1;

@Injectable({
  providedIn: 'root'
})
/**
 * @see https://blog.thoughtram.io/angular/2018/03/05/advanced-caching-with-rxjs.html
 */
export class CarSaleCacheService {

  private cache: Observable<CarSale>;

  constructor(private carSaleService: CarSaleService) {
  }

  getCarSale() {
    if (!this.cache) {
      this.cache = this.carSaleService.getCarSale().pipe(shareReplay(CACHE_SIZE));
    }
    return this.cache;
  }

  clear() {
    this.cache = null;
  }
}
