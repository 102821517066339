import { ApiModule } from '@coc-kfz-digital/oma-rest-api-client';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { OrderModule } from 'ngx-order-pipe';
import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { NavigationMobileComponent } from './navigation-bar-mobile/navigation-bar-mobile.component';
import { NavigationComponent } from './navigation-bar/navigation-bar.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TariffBenefitListComponent } from './results-pages/benefit-list/tariff-benefit-list.component';
import { InsurerLogoComponent } from './results-pages/insurer-logo/insurer-logo.component';
import { NoResultTileComponent } from './results-pages/no-result-tile/no-result-tile.component';
import { TariffTileComponent } from './results-pages/tariff-tile/tariff-tile.component';
import { TileListComponent } from './results-pages/tile-list/tile-list.component';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';
import {RouterModule} from '@angular/router';
import {MaFabButtonComponent, MaFabDialogComponent} from './ma-fab-button/ma-fab-button.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {SvgComponent} from './svg_component/svg.component';
import {MaterialCssVarsModule} from 'angular-material-css-vars';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SafeHtmlPipe,
    TariffTileComponent,
    InsurerLogoComponent,
    TileListComponent,
    NoResultTileComponent,
    SpinnerOverlayComponent,
    TariffBenefitListComponent,
    NavigationComponent,
    NavigationMobileComponent,
    MaFabButtonComponent,
    MaFabDialogComponent,
    SvgComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    BrowserModule,
    OrderModule,
    BrowserModule,
    HttpClientModule,
    ApiModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressBarModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MaterialCssVarsModule.forRoot({
      isAutoContrast: true,
      darkThemeClass: 'isDarkTheme',
      lightThemeClass: 'isLightTheme',
    }),
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SafeHtmlPipe,
    TileListComponent,
    TariffTileComponent,
    InsurerLogoComponent,
    NoResultTileComponent,
    SpinnerOverlayComponent,
    TariffBenefitListComponent,
    NavigationComponent,
    NavigationMobileComponent,
    MaFabButtonComponent,
    MaFabDialogComponent,
    SvgComponent,
  ],
  entryComponents: [
    MaFabDialogComponent
  ],
})
export class OmaCommonModule { }
