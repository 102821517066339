import {
  CarSaleService, CarInsuranceProposalContractDocumentsService,
  Consent, InlineResponse200, CarInsuranceProposalContractDocumentsConsentService
} from '@coc-kfz-digital/oma-rest-api-client';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {forkJoin, of, Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {PathNames} from 'src/app/app-routing-pathnames';
import {toRestoreStateObservable} from 'src/app/common/request-parameter-utils';
import {safeUnsubscribe} from 'src/app/shared/utils';
import {environment} from 'src/environments/environment';
import {FormValues} from '../../dynamic-form/containers/dynamic-form/dynamic-form.component';
import {AbstractResultPageComponent} from '../abstract-result-page/abstract-result-page.component';
import {BrandingFacadeService} from '../../common/services/branding-facade.service';

@Component({
  selector: 'app-summary-page',
  templateUrl: './summary-page.component.html',
  styleUrls: ['./summary-page.component.scss']
})
export class SummaryPageComponent extends AbstractResultPageComponent implements OnInit, OnDestroy {

  submitted = false;

  isSpinnerVisible = false;

  restoreState: FormValues;
  consentSubscription: Subscription;
  restoreStateSubscription: Subscription;
  summaryForm: FormGroup;

  consignmentSubscription: Subscription;

  public telephoneNumber$ = this.brandingService.phone$;
  public email$ = this.brandingService.email$;

  constructor(private carSaleService: CarSaleService, private contractDocumentsService: CarInsuranceProposalContractDocumentsService,
              private contractDocumentsConsentService: CarInsuranceProposalContractDocumentsConsentService,
              public brandingService: BrandingFacadeService) {
    super();
    this.filterBySelectedTariff = true;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.buildForm();

    this.restoreStateSubscription = this.getJoinedRestoreStateObservable().subscribe(data => {
      this.restoreState = this.concatStates(data.calcRestoreState, data.propRestoreState);
    });
    this.registerChangeListeners();

    this.loadCheckboxValues();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.carSale.applicationState = this.authService.currentApplicationState();
    // cleanup subscriptions
    safeUnsubscribe(this.restoreStateSubscription);
  }

  /**
   * method for retrieving the calculation and proposal page restore state observables
   * using forkJoin operator.
   */
  private getJoinedRestoreStateObservable() {
    return forkJoin({
      calcRestoreState: toRestoreStateObservable(this.carInsuranceCalculationService.getCalculation()),
      propRestoreState: toRestoreStateObservable(this.carInsuranceProposalService.getProposal())
    });
  }

  /**
   * method for concatenating two restore states into a single restoreState.
   * in the template we can then refer to only one 'restoreState' object.
   */
  private concatStates(stateA: FormValues, stateB: FormValues) {
    const restoreState: FormValues = {};

    Object.keys(stateA).forEach(key => {
      restoreState[key] = stateA[key];
    });

    Object.keys(stateB).forEach(key => {
      restoreState[key] = stateB[key];
    });

    return restoreState;
  }

  private buildForm() {
    this.summaryForm = this.formBuilder.group({
      consignment: ['', [Validators.requiredTrue]],
      consultingWaiver: ['', [Validators.requiredTrue]]
    });
  }

  /**
   * Event handler method that is invoked by submitting the page form
   */
  onSubmit() {
    this.submitted = true;

    this.consentSubscription = this.getJoinedConsentObservable().subscribe({
      next: response => this.handleConsentResponse(response),
      error: () => this.submitted = false,
    });
  }

  private handleConsentResponse(response: InlineResponse200) {
    // safeUnsubscribe(this.consentSubscription);
    if (response.success === true) {
      this.router.navigate([PathNames.proposalResultPage.url, this.currentCarSaleId()]);
    }
  }

  /**
   * creating an observable for each consent object and joining them via forkJoin.
   * the resulting observable response gets transformed via the switchMap operator
   * in order to fit as a parameter into the handleConsentResponse method.
   */
  private getJoinedConsentObservable() {

    const consentContractDetails: Consent = {
      name: Consent.NameEnum.ContractDetails,
      version: environment.consent_versions.contract_details,
      accept: true,
    };

    const consentConsultingWaiver: Consent = {
      name: Consent.NameEnum.ConsultingWaiver,
      version: environment.consent_versions.consulting_waiver,
      accept: true,
    };

    return forkJoin({
      consignmentConsent: this.carSaleService.createCustomerConsent(consentContractDetails),
      consultingWaiverConsent: this.carSaleService.createCustomerConsent(consentConsultingWaiver)
    }).pipe(switchMap(response => {
      const success = response.consignmentConsent.success === true && response.consultingWaiverConsent.success === true;
      const combinedConsent: InlineResponse200 = {success};
      return of(combinedConsent);
    }));
  }

  private loadCheckboxValues() {

    this.contractDocumentsConsentService.existsContractDocumentsConsent().subscribe(result => {
      this.summaryForm.controls.consignment.setValue(result.existsConsent);
    });
  }

  private registerChangeListeners() {

    this.summaryForm.addControl('consignment', this.formBuilder.control(false));

    // change listener
    this.consignmentSubscription = this.summaryForm.controls.consignment.valueChanges.subscribe(value => {
      this.sendDocumentEmail(value);
    });
  }

  // onConsentChange(consent: Consent) {
  //   this.consentSubscription = this.carSaleService.createCustomerConsent(consent).subscribe();
  //   const applicationState = this.authService.currentApplicationState();
  //   const index = applicationState.consents.findIndex(entry => entry.name === consent.name && entry.version === consent.version);
  //   if (index >= 0) {
  //     applicationState.consents[index] = consent;
  //   } else {
  //     applicationState.consents.push(consent);
  //   }
  //   this.authService.updateApplicationState(applicationState);
  // }

  editSection(section: string) {
    switch (section) {
      case 'quickCalculation': {
        this.router.navigate([PathNames.quickCalculationPage.url, this.currentCarSaleId()]);
        break;
      }
      case 'detailCalculation': {
        this.router.navigate([PathNames.detailedCalculationPage.url, this.currentCarSaleId()]);
        break;
      }
      case 'previousInsurer': {
        this.router.navigate([PathNames.previousInsurerPage.url, this.currentCarSaleId()]);
        break;
      }
      case 'paymenent': {
        this.router.navigate([PathNames.paymentDetailsPage.url, this.currentCarSaleId()]);
        break;
      }
      default: {
        break;
      }
    }
  }

  sendDocumentEmail(value: boolean) {
    if (value) {
      this.isSpinnerVisible = true;

      this.contractDocumentsService.sendContractDocumentsEmail().subscribe({
        next: response => {
          if (response.sendSuccessfull) {
            this.isSpinnerVisible = false;
          }
        },
        error: () => {
          this.summaryForm.controls.consignment.setValue(false);
          this.isSpinnerVisible = false;
        }
      });
    }
  }
}
