import {Component, Inject} from '@angular/core';
import {AbstractFormFieldComponent} from '../abstract-form-field/abstract-form-field.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BrandingFacadeService} from '../../../common/services/branding-facade.service';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss'],
})
export class FormModalComponent extends AbstractFormFieldComponent {

  public telephoneNumber$ = this.brandingService.phone$;

  constructor(private dialogRef: MatDialogRef<FormModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              public brandingService: BrandingFacadeService) {
    super();
  }

  onNoClick(){
    this.dialogRef.close();
  }
}
