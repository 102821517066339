export const environmentBase = {
  production: false,
  API_BASE_PATH: 'http://localhost:8080',
  dynamic_form_debug: false,
  application_state_debug: false,
  // Session expiration in seconds (2 hours -> 7200 seconds)
  session_expiration: 7200,
  url_impressum: 'https://www.techno-vd.de/impressum.html',
  url_datenschutz: 'https://www.techno-vd.de/datenschutz.html',
  url_nutzungsbedingungen: 'https://www.techno-vd.de/nutzungsbedingungen.html',
  google: {
    tag_manager: 'GTM-XXXXXX',
    analytics: 'UA-XXXXXXXXX-X'
  },
  consent_versions: {
    data_protection_general_terms: '1',
    consultation_via_email: '1',
    consultation_via_telephone: '1',
    contract_details: '1',
    consulting_waiver: '1'
  },
};
