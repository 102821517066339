import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { PathNames, PathParams } from './app-routing-pathnames';
import { AuthService } from './common/services/auth.service';


@Injectable()
export class CanActivateRouteGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const carSaleId = route.paramMap.get(PathParams.carSaleId);
        const isAuthenticated = this.authService.isAuthenticated(carSaleId);

        // redirect to login page
        if (!isAuthenticated) {
            if (carSaleId) {
                // redirect to login page with carsale id
                this.router.navigate([PathNames.loginPage.url, carSaleId]);
            } else {
                // redirect to login page without carsale id
                this.router.navigate([PathNames.loginPage.url]);
            }
        }

        // return route activation flag
        return isAuthenticated;
    }
}
