import { Component } from '@angular/core';
import {BrandingFacadeService} from '../../common/services/branding-facade.service';

@Component({
  selector: 'app-general-error-page',
  templateUrl: './general-error-page.component.html',
  styleUrls: ['./general-error-page.component.scss']
})
export class GeneralErrorPageComponent {

  public telephoneNumber$ = this.brandingService.phone$;
  public email$ = this.brandingService.email$;

  constructor(public brandingService: BrandingFacadeService) {}

  returnToLastPage() {
    window.history.back();
  }
}
