import { FormUiSpecificationName } from '@coc-kfz-digital/oma-rest-api-client';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PathNames } from 'src/app/app-routing-pathnames';
import { createRequest } from 'src/app/common/request-parameter-utils';
import { FormValues } from 'src/app/dynamic-form/containers/dynamic-form/dynamic-form.component';
import { AbstractDynamicFormPageComponent } from '../abstract-dynamic-form-page/abstract-dynamic-form-page.component';

@Component({
  selector: 'app-detailed-calculation-page',
  templateUrl: './detailed-calculation-page.component.html',
  styleUrls: ['./detailed-calculation-page.component.scss']
})
export class DetailedCalculationPageComponent extends AbstractDynamicFormPageComponent implements OnInit, OnDestroy {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected getPageName(): FormUiSpecificationName {
    return FormUiSpecificationName._2DetailedCalculationStepJson;
  }

  /**
   * Event handler method that is invoked by submitting the detail calculation page form
   * @param formValues the state of the form during submission
   */
  onSubmitted(formValues: FormValues) {
    this.status.submitted = true;

    // prepare calculation request to store form state
    const calculationRequest = createRequest(formValues);

    // create calculation request observable
    const calcRequestObservable = this.carInsuranceCalculationService.patchCalculation(calculationRequest);

    // save calculation request
    calcRequestObservable.subscribe({
      complete: () => {
        this.carSaleCacheService.clear();
        this.router.navigate([PathNames.detailedResultsPage.url, this.currentCarSaleId()]);
      },
      error: () => this.status.submitted = false,
    });
  }
}

