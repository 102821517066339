import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Pipe to interpret given html content by bypassing the Dom Sanitizer.
 *
 * **Security risk:** Please use this pipe with special care.
 * Carefully check and audit all values and code paths going into this call.
 * Make sure any user data is appropriately escaped for this security context.
 *
 * **Usage:**
 * ```
 * <div [innerHTML]="someHtml | safeHtml">
 * ```
 * @see https://stackoverflow.com/questions/37076867/in-rc-1-some-styles-cant-be-added-using-binding-syntax/37076868#37076868
 * @see https://angular.io/api/platform-browser/DomSanitizer#bypasssecuritytrusthtml
 */
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
