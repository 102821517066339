import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
    /**
     * @description
     * Validator that compares two control's date values.
     * The validator requires the value of the given 'from' control to be smaller (earlier in time)
     * than the value of the given 'to' control.
     *
     * @param from the control's name inside the form group with the smaller/earlier date
     * @param to the control's name inside the form group with the higher/later date
     *
     * @usageNotes
     *
     * ### Validate that the field 'birthdateOldestDriver' has an earlier date thant the field 'birthdateYoungestDriver'.
     *
     * ```typescript
     * this.form = this.fb.group(
     *      {
     *          'birthdateYoungestDriver': new FormControl(),
     *          'birthdateOldestDriver': new FormControl(),
     *      },
     *      {
     *          validators: CustomValidators.dateLessThan('birthdateOldestDriver', 'birthdateYoungestDriver')
     *      }
     * );
     *
     * console.log(form.errors);
     * // {
     * //   dateLessThan: {
     * //       requiredDateLessThan: true,
     * //       from: {'birthdateOldestDriver', value: ''},
     * //       to: {'birthdateYoungestDriver', value: ''},
     * //       dates: 'Date from should be less than Date to.'
     * //   }
     * // }
     * ```
     *
     * @returns A validator function that returns an error map with the
     * `dateLessThan` property if the validation check fails, otherwise `null`.
     */
    static dateLessThan(from: string, to: string): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {

            if (control === undefined || control.parent === undefined) {
                return {};
            }
            const controls = control.parent.controls;

            const f = controls[from];
            const t = controls[to];

            if (f === undefined || t === undefined || f.disabled || t.disabled) {
                return {};
            }

            const fDate = new Date(f.value);
            const tDate = new Date(t.value);

            if (fDate > tDate) {
                return {
                    dateLessThan: {
                        requiredDateLessThan: true,
                        from: { from, value: fDate },
                        to: { to, value: tDate },
                        dates: 'Date from should be less than Date to.'
                    }
                };
            }
            return {};
        };
    }
}
