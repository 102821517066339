import {Injectable} from '@angular/core';
import {MaterialCssVarsService} from 'angular-material-css-vars';
import {NavigationEnd, Router} from '@angular/router';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {PathParams} from '../../app-routing-pathnames';
import {Branding, BrandingService} from '@coc-kfz-digital/oma-rest-api-client';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrandingFacadeService {

  private DEFAULT_COLOR = '#55ab26';

  private DEFAULT_BRANDING: Branding = {
    clientId: null,
    name: null,
    email: 'service@techno-vd.de',
    phone: '0911 477 67 444',
    logoUrl: null,
    agbUrl: null,
    dataPolicyUrl: null,
    colorCode: this.DEFAULT_COLOR
  }

  private branding$: BehaviorSubject<Branding> = new BehaviorSubject<Branding>(this.DEFAULT_BRANDING);

  public carSaleId$: Observable<string>;
  public name$: Observable<string> =
    this.branding$.pipe(map(branding => branding.name), distinctUntilChanged());
  public logoUrl$: Observable<string> =
    this.branding$.pipe(map(branding => branding.logoUrl), distinctUntilChanged());
  public agbUrl$: Observable<string> =
    this.branding$.pipe(map(branding => branding.agbUrl), distinctUntilChanged());
  public email$: Observable<string> =
    this.branding$.pipe(map(branding => branding.email), distinctUntilChanged());
  public phone$: Observable<string> =
    this.branding$.pipe(map(branding => branding.phone), distinctUntilChanged());
  public dataPolicyUrl$: Observable<string> =
    this.branding$.pipe(map(branding => branding.dataPolicyUrl), distinctUntilChanged());
  public color$: Observable<string> =
    this.branding$.pipe(map(branding => branding.colorCode ?? this.DEFAULT_COLOR), distinctUntilChanged());

  private LOCAL_STORAGE_KEY = 'branding';

  constructor(
    private route: Router,
    private materialCssVarsService: MaterialCssVarsService,
    private brandingService: BrandingService
  ) {

    const jsonString = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    if (jsonString) {
      const branding = JSON.parse(jsonString);
      this.branding$.next({
        ...this.DEFAULT_BRANDING,
        ...branding
      });
    }

    this.carSaleId$ = this.route.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(_ => this.route.routerState.snapshot.root.firstChild.paramMap.get(PathParams.carSaleId)),
      // do not use distinctUntilChanged here, or display of logout button will not work correctly
    );

    this.carSaleId$.subscribe((carSaleId: string) => {

      if (!carSaleId) {
        // keep previously working branding
        return;
      }

      this.brandingService.getBranding(carSaleId).subscribe(
        branding => {
          localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(branding));
          this.branding$.next(branding)
        },
        error => { /* keep previously working branding */
        }
      );
    });

    this.color$.subscribe(
      color => {
        this.materialCssVarsService.setPrimaryColor(color);
        this.materialCssVarsService.setAccentColor(color);
        document.documentElement.style.setProperty('--primary-color', color);
      }
    )
  }
}

