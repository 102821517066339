import {Component, OnInit} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {PathParams} from 'src/app/app-routing-pathnames';
import {AbstractPageComponent} from '../abstract-page/abstract-page.component';
import {of, Subscription} from 'rxjs';
import {CarSaleService, Consent, InlineResponse200, Name} from '@coc-kfz-digital/oma-rest-api-client';
import {environment} from 'src/environments/environment';
import {safeUnsubscribe} from '../../shared/utils';
import {switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent extends AbstractPageComponent implements OnInit {

  submitted = false;

  loginForm: FormGroup;
  customerName: Name;
  carSaleId: string;
  showLoginErrorMessage: boolean;
  showConsultationCheckboxes: boolean;
  showAuthErrorMessage: boolean;

  carSaleSubscription: Subscription;
  consentSubscription: Subscription;

  constructor(private route: ActivatedRoute, private carSaleService: CarSaleService) {
    super();
  }

  ngOnInit() {
    this.carSaleCacheService.clear();
    super.ngOnInit();
    this.showConsultationCheckboxes = this.authService.showConsultationConsents();
    this.buildForm();

    // get carsale id from request
    this.carSaleId = this.route.snapshot.paramMap.get(PathParams.carSaleId);

    if (!this.carSaleId) {
      this.customerName = {
        gendericedName: ''
      };
      return;
    }
    this.carSaleService.getCustomerName(this.carSaleId).subscribe(name => this.customerName = name);

    if (this.authService.isAuthenticated(this.carSaleId)) {
      this.redirectToLastPageIfPossible();
    } else {
      this.authService.login(this.carSaleId).subscribe(
        data => {
          this.showLoginErrorMessage = !data;
        },
        err => {
          this.showLoginErrorMessage = !err;
        }
      );
    }
  }


  private buildForm() {
    this.loginForm = this.formBuilder.group({
      smsToken: ['', [Validators.required, Validators.pattern('[0-9]{6}')]],
      isDataAgreementConfirmed: [false, Validators.requiredTrue]
    });
  }

  private onSubmit(smsToken: string) {

    // Check if form is valid to prevent submit with invalid form
    if (!this.loginForm.valid) {
      return;
    }

    this.submitted = true;

    this.authService.authenticate(smsToken).subscribe(
      data => {

        this.consentSubscription = this.getConsentObservable()
          .subscribe({
            next: (response) => this.handleConsentResponse(response),
            error: () => this.submitted = false,
          });

        this.showAuthErrorMessage = !data;
        this.loginForm.get('smsToken').setErrors({smsToken: this.showAuthErrorMessage})

        if (data) {
          this.redirectToLastPageIfPossible();
        } else {
          this.submitted = false;
        }
      },
      err => {
        this.showAuthErrorMessage = !err;
        this.submitted = false;
      }
    );
  }

  private redirectToLastPageIfPossible() {
    if (this.authService.isAuthenticated(this.carSaleId)) {
      this.authService.redirectToResumePage(true);
    }
  }

  private handleConsentResponse(response: InlineResponse200) {
    safeUnsubscribe(this.consentSubscription);
    if (response.success === true) {
      this.carSaleCacheService.clear();
      this.authService.redirectToResumePage(false);
    }
  }

  /**
   * creating an observable for each consent object and joining them via forkJoin.
   * the resulting observable response gets transformed via the switchMap operator
   * in order to fit as a parameter into the handleConsentResponse method.
   */
  private getConsentObservable() {

    const dataProtectionConsent: Consent = {
      name: Consent.NameEnum.DataProtectionGeneralTerms,
      version: environment.consent_versions.data_protection_general_terms,
      accept: true,
    };

    const consentObservables = this.carSaleService.createCustomerConsent(dataProtectionConsent);

    return consentObservables.pipe(switchMap(response => {
      const success = response.success;

      const combinedConsent: InlineResponse200 = {success};
      return of(combinedConsent);
    }));
  }
}
