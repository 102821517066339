import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-svg',
  template: `
    <ng-template>
      {{ src }}
    </ng-template>
    <span [innerHTML]="svg"></span>
  `,
  styles: [
    ':host { display: inline-block }'
  ]
})
export class SvgComponent implements OnInit {

  svg: SafeHtml = '';
  @Input() public src = '';

  constructor(private http: HttpClient, private sanitize: DomSanitizer) {
  }

  ngOnInit(): void {
    this.http.get(this.src, {responseType: 'text'}).subscribe(svg => {
      this.svg = this.sanitize.bypassSecurityTrustHtml(svg);
    });
  }
}
