import { AbstractRequest, Parameter } from '@coc-kfz-digital/oma-rest-api-client';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FormValues } from '../dynamic-form/containers/dynamic-form/dynamic-form.component';

/**
 * Creates a request object based on the given form state
 * @param formValues the state of the form
 */
export function createRequest(formValues: FormValues): AbstractRequest {

    // intialize request
    const request: AbstractRequest = {
        parameters: []
    };

    populateRequest(request, formValues);

    return request;
}

/**
 * Populates the given request with request parameters defined by given formValues
 * @param request the request to populate
 * @param formValues the form values
 */
export function populateRequest(request: AbstractRequest, formValues: FormValues) {
    // convert form values to parameters
    Object.keys(formValues).forEach(key => {
        const value = formValues[key];
        const calcParameter = createParameter(key, value);
        request.parameters.push(calcParameter);
    });
}

/**
 * Creates a parameter object with given name and value
 * @param name the name of the parameter
 * @param value the value of the parameter
 */
export function createParameter(name: string, value: string): Parameter {
    return {
        name,
        value,
    };
}

/**
 * Converts the given rest request object to restore state object
 * @param request the request from rest api call
 * @returns the restore state
 */
export function toRestoreState(request: AbstractRequest): FormValues {
    // clear current restore state
    const restoreState = {};

    if (request.parameters) {
        // add all parameters to restore state
        request.parameters.forEach(parameter => {
            restoreState[parameter.name] = parameter.value;
        });
    }
    return restoreState;
}

/**
 * Converts the given request observable to a form value obserable
 *
 * @param request the request to convert
 */
export function toRestoreStateObservable(request: Observable<AbstractRequest>): Observable<FormValues> {
    return request.pipe(switchMap(value => of(toRestoreState(value))));
}
