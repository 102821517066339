import { Tariff } from '@coc-kfz-digital/oma-rest-api-client';
import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-tariff-tile',
  templateUrl: './tariff-tile.component.html',
  styleUrls: ['./tariff-tile.component.scss']
})
export class TariffTileComponent {
  constructor() { }

  displayedBenefits: Array<string> = [
    'AUSLANDSSCHADENSCHUTZ',
    'WILDSCHAEDEN_PLUS_INKLUSIVE',
    'S_PARKSCHAEDEN_MITVERSICHERT',
    'S_TIERBISS_MITVERSICHERT',
  ];

  moreBenefits: Array<string> = [
    'S_TIERBISSFOLGESCHAEDEN_MITVERSICHERT',
    'S_EIGENSCHAEDEN_MITVERSICHERT',
    'MALLORCA_POLICE_INKLUSIVE',
    'VERZICHT_DES_EINWANDS_DER_GROBEN_FAHRLAESSIGKEIT',
    'S_KAUFWERTENTSCHAEDIGUNG',
    'SONDERAUSSTATTUNG_MITVERSICHERT',
    'VERZICHT_DER_SELBSTBETEILIGUNG_BEI_GLASBRUCHSCHAEDEN',
    'ELEMENTAR_PLUS_INKLUSIVE',
  ];


  @Input() tariff: Tariff = {};

  @Input() selectTariffLabel: string;

  @Input() submitted = false;

  @Output()
  selected: EventEmitter<Tariff> = new EventEmitter<Tariff>();

  displayMore = false;

  toogleShowMore() {
    this.displayMore = !this.displayMore;
  }
}
