import { FormUiSpecificationName } from '@coc-kfz-digital/oma-rest-api-client';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { PathNames } from 'src/app/app-routing-pathnames';
import { createRequest } from 'src/app/common/request-parameter-utils';
import { FormQuickToggleDatepickerComponent } from 'src/app/dynamic-form/components/form-quick-toggle-datepicker/form-quick-toggle-datepicker.component';
import { AfterFormInitializationHookEvent, FormValues } from 'src/app/dynamic-form/containers/dynamic-form/dynamic-form.component';
import { AbstractDynamicFormPageComponent } from '../abstract-dynamic-form-page/abstract-dynamic-form-page.component';

@Component({
  selector: 'app-quick-calculation-page',
  templateUrl: './quick-calculation-page.component.html',
  styleUrls: ['./quick-calculation-page.component.scss']
})
export class QuickCalculationPageComponent extends AbstractDynamicFormPageComponent implements OnInit, OnDestroy {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected getPageName(): FormUiSpecificationName {
    return FormUiSpecificationName._1QuickCalculationStepJson;
  }

  onAfterFormInitialization(event: AfterFormInitializationHookEvent) {
    this.resetExpiredRegistrationDate(event.form);
  }

  /**
   * Reset registration date to today's date if expired
   *
   * @param form the form
   */
  private resetExpiredRegistrationDate(form: FormGroup) {
    const registrationDateToggleName = FormQuickToggleDatepickerComponent.getToggleControlNameForFieldName('registrationDate');
    const registrationDate = form.controls.registrationDate;
    const registrationDateToggle = form.controls[registrationDateToggleName];
    const value = registrationDate.value;

    if (value) {
      const today = moment().startOf('day');
      const currentRegistrationDate = moment(value).startOf('day');

      if (currentRegistrationDate.isBefore(today)) {
        registrationDate.setValue(today);
        registrationDateToggle.setValue('#today');
      }
    }
  }

  /**
   * Event handler method that is invoked by submitting the quick calculation page form
   * @param formValues the state of the form during submission
   */
  onSubmitted(formValues: FormValues) {
    this.status.submitted = true;

    // prepare calculation request to store form state
    const calculationRequest = createRequest(formValues);

    // create calculation request observable
    const calcRequestObservable = this.carInsuranceCalculationService.patchCalculation(calculationRequest);

    // save calculation request
    calcRequestObservable.subscribe({
      complete: () => {
        this.router.navigate([PathNames.comparativeResultsPage.url, this.currentCarSaleId()]);
      },
      error: () => this.status.submitted = false
    });
  }
}
