import { Tariff } from '@coc-kfz-digital/oma-rest-api-client';
import { Component } from '@angular/core';
import { PathNames } from 'src/app/app-routing-pathnames';
import { AbstractResultPageComponent } from '../abstract-result-page/abstract-result-page.component';

@Component({
  selector: 'app-detailed-results-page',
  templateUrl: './detailed-results-page.component.html',
  styleUrls: ['./detailed-results-page.component.scss']
})
export class DetailedResultsPageComponent extends AbstractResultPageComponent {

  constructor() {
    super();
    this.filterBySelectedTariff = true;
    this.lastPageRouting = { commands: [PathNames.detailedCalculationPage, this.currentCarSaleId()] };
  }

  onSelect(tariff: Tariff) {
    this.submitted = true;
    this.router.navigate([PathNames.previousInsurerPage.url, this.currentCarSaleId()]);
    this.submitted = false;
  }
}
