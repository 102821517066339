import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanActivateRouteGuard } from './app-routing-can-activate-route.guard';
import { ApplicationFlowGuard } from './app-routing-application-flow.guard';
import { PathNames, PathParams } from './app-routing-pathnames';
import { ComparativeResultsPageComponent } from './views/comparative-results-page/comparative-results-page.component';
import { DetailedCalculationPageComponent } from './views/detailed-calculation-page/detailed-calculation-page.component';
import { DetailedResultsPageComponent } from './views/detailed-results-page/detailed-results-page.component';
import { GeneralErrorPageComponent } from './views/general-error-page/general-error-page.component';
import { LoginPageComponent } from './views/login-page/login-page.component';
import { PaymentDetailsPageComponent } from './views/payment-details-page/payment-details-page.component';
import { PreviousInsurerPageComponent } from './views/previous-insurer-page/previous-insurer-page.component';
import { ProposalResultPageComponent } from './views/proposal-result-page/proposal-result-page.component';
import { QuickCalculationPageComponent } from './views/quick-calculation-page/quick-calculation-page.component';
import { SummaryPageComponent } from './views/summary-page/summary-page.component';
import { PrivacyNoticeComponent} from './views/privacy-notice/privacy-notice.component';
import {ImprintPageComponent} from './views/imprint-page/imprint-page.component';
import {TermsOfServiceComponent} from './views/terms-of-service/terms-of-service.component';

export const routes: Routes = [

  // login page: default
  {
    path: PathNames.loginPage.url + '/:' + PathParams.carSaleId,
    component: LoginPageComponent
  },
  // login page: error handling for missing carSaleId
  {
    path: PathNames.loginPage.url,
    component: LoginPageComponent
  },

// Privacy Notice page
  {
    path: PathNames.privacyNotice.url,
    component: PrivacyNoticeComponent,
  },
  // Imprint page
  {
    path: PathNames.imprintPage.url,
    component: ImprintPageComponent,
  },
  {
    path: PathNames.termsOfService.url,
    component: TermsOfServiceComponent,
  },

  // ordinary page routes
  {
    path: PathNames.summaryPage.url + '/:' + PathParams.carSaleId,
    component: SummaryPageComponent,
    canActivate: [CanActivateRouteGuard, ApplicationFlowGuard]
  },
  {
    path: PathNames.quickCalculationPage.url + '/:' + PathParams.carSaleId,
    component: QuickCalculationPageComponent,
    canActivate: [CanActivateRouteGuard, ApplicationFlowGuard]
  },
  {
    path: PathNames.comparativeResultsPage.url + '/:' + PathParams.carSaleId,
    component: ComparativeResultsPageComponent,
    canActivate: [CanActivateRouteGuard, ApplicationFlowGuard]
  },
  {
    path: PathNames.detailedCalculationPage.url + '/:' + PathParams.carSaleId,
    component: DetailedCalculationPageComponent,
    canActivate: [CanActivateRouteGuard, ApplicationFlowGuard]
  },
  {
    path: PathNames.detailedResultsPage.url + '/:' + PathParams.carSaleId,
    component: DetailedResultsPageComponent,
    canActivate: [CanActivateRouteGuard, ApplicationFlowGuard]
  },
  {
    path: PathNames.previousInsurerPage.url + '/:' + PathParams.carSaleId,
    component: PreviousInsurerPageComponent,
    canActivate: [CanActivateRouteGuard, ApplicationFlowGuard]
  },
  {
    path: PathNames.paymentDetailsPage.url + '/:' + PathParams.carSaleId,
    component: PaymentDetailsPageComponent,
    canActivate: [CanActivateRouteGuard, ApplicationFlowGuard]
  },
  {
    path: PathNames.proposalResultPage.url + '/:' + PathParams.carSaleId,
    component: ProposalResultPageComponent,
    canActivate: [CanActivateRouteGuard, ApplicationFlowGuard]
  },
  // routes to login page in case we have no carsaleId parameter
  {
    path: PathNames.summaryPage.url,
    redirectTo: PathNames.loginPage.url,
  },
  {
    path: PathNames.quickCalculationPage.url,
    redirectTo: PathNames.loginPage.url,
  },
  {
    path: PathNames.comparativeResultsPage.url,
    redirectTo: PathNames.loginPage.url,
  },
  {
    path: PathNames.detailedCalculationPage.url,
    redirectTo: PathNames.loginPage.url,
  },
  {
    path: PathNames.detailedResultsPage.url,
    redirectTo: PathNames.loginPage.url,
  },
  {
    path: PathNames.previousInsurerPage.url,
    redirectTo: PathNames.loginPage.url,
  },
  {
    path: PathNames.paymentDetailsPage.url,
    redirectTo: PathNames.loginPage.url,
  },
  {
    path: PathNames.proposalResultPage.url,
    redirectTo: PathNames.loginPage.url,
  },
  // fallback routes
  {
    path: PathNames.errorPage.url,
    component: GeneralErrorPageComponent
  },
  {
    path: PathNames.empty.url,
    redirectTo: PathNames.loginPage.url,
    pathMatch: 'full'
  },
  {
    path: PathNames.pageNotFoundPage.url,
    component: GeneralErrorPageComponent
  },
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top'
    }),
  ],
  exports: [
    RouterModule,
  ],
  providers: [CanActivateRouteGuard, ApplicationFlowGuard]
})
export class AppRoutingModule { }
