import { Component } from '@angular/core';
import { AbstractFormFieldComponent } from '../abstract-form-field/abstract-form-field.component';

@Component({
  selector: 'app-form-checkmarkbox',
  templateUrl: './form-checkmarkbox.component.html',
  styleUrls: ['./form-checkmarkbox.component.scss']
})
export class FormCheckmarkboxComponent extends AbstractFormFieldComponent {

  onClick() {
    // toogle checkbox value
    const invertedControlValue = !this.control().value;
    this.control().setValue(invertedControlValue);
  }
}
